import React, { useState, useCallback } from 'react';

// Auth Context
import { useSession } from '../../../../context/AuthSession';

// Material-ui
import { styled } from '@mui/material/styles';
import { Paper, Typography, TextField, TableCell, IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// Theme components Material-ui
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange, red } from '@mui/material/colors';

// devexpress grid components
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider,
  PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';

// devexpress grid components Material-ui
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableKeyboardNavigation,
  TableFilterRow,
  TableSelection,
  TableInlineCellEditing,
  Toolbar,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

// Helper functions
import {
  SINMaskingFormatter,
  StaticEditCell,
  LookupEditCell,
  NativeDateEditCell,
  NativeDateFilterCell,
  LookupFilterCell,
  greaterDate,
} from '../../../../common/HelperFunctions';

// Third party
import { useSnackbar } from 'notistack';
import { isAfter, isBefore, addMonths, parse } from 'date-fns';

// Icons
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';

// Styled Focusable Cell
export const FocusableCell = ({ onClick, ...props }) => {
  return <VirtualTable.Cell {...props} tabIndex={0} onFocus={onClick} />;
};

// Styled Tooltip - Error Display
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2d3748',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2d3748',
  },
}));

// styled Edit Cell
const StyledEditCell = styled(TableInlineCellEditing.Cell)(({ theme }) => ({
  [`&.TableEditCell-cell`]: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

// Theme for the cell component
const cellTheme = createTheme({
  palette: {
    type: 'light',
    primary: orange,
  },
});

// Cell component
const CellComponent = ({ row, selected, onToggle, children }) => {
  return (
    <ThemeProvider theme={cellTheme}>
      <TableSelection.Cell row={row} onToggle={onToggle} selected={selected}>
        {children}
      </TableSelection.Cell>
    </ThemeProvider>
  );
};

// Theme for the header cell component
const headerTheme = createTheme({
  palette: {
    type: 'light',
    primary: red,
  },
});

// Header cell component
const HeaderCellComponent = ({ disabled, allSelected, someSelected, onToggle, children }) => {
  return (
    <ThemeProvider theme={headerTheme}>
      <TableSelection.HeaderCell
        disabled={disabled}
        allSelected={allSelected}
        someSelected={someSelected}
        onToggle={onToggle}
      >
        {children}
      </TableSelection.HeaderCell>
    </ThemeProvider>
  );
};

// Header content component
const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: 'bold',
      fontSize: '1rem',
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

// Data Masking Provider
const DataMaskingProvider = (props) => (
  <DataTypeProvider formatterComponent={SINMaskingFormatter} {...props} />
);

// Custom Filter Cell
const FilterCell = (props) => {
  const { column } = props;

  if (['edits'].includes(column.name)) {
    return <TableCell sx={{ width: '100%', px: 1, py: 0 }} />;
  }

  if (['workstatus', 'employmenttype'].includes(column.name)) {
    return (
      <TableCell sx={{ width: '100%', px: 1, py: 0 }}>
        <LookupFilterCell
          accountid={props.accountid}
          name={column.name}
          filter={props.filter}
          readOnlyMode={false}
          onFilter={props.onFilter}
          authToken={props.authToken}
          {...props}
        />
      </TableCell>
    );
  }

  if (['workstatusstartdate', 'workstatusenddate'].includes(column.name)) {
    return (
      <TableCell sx={{ width: '100%', px: 1, py: 0 }}>
        <NativeDateFilterCell filter={props.filter} onFilter={props.onFilter} {...props} />
      </TableCell>
    );
  }

  return <TableFilterRow.Cell {...props} />;
};

// Custom Edit Cell Component
const EditCell = ({
  accountid,
  authToken,
  ltdCompany,
  isEditable,
  onClaimEmployeeIds,
  onPensionPaymentEmployeesIds,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  // Check if the Employee id is part of the list of employees on LTD Claim.
  const isClaimedEmployee = onClaimEmployeeIds.includes(props.row.employeelistid);
  const workStatusRequire = ["A", "D", "M", "P", "R", "T"];
  const isValid = (value) => value !== undefined && value !== null && value !== "" && workStatusRequire.includes(value.trim());

  if (props.column.name === 'edits' && props.row.edits.length !== 0) {
    const title = props.row.edits.join('\n');

    return (
      <StyledEditCell {...props} tabIndex={0} title={title}>
        <ErrorIcon color={'secondary'} fontSize={'small'} />
      </StyledEditCell>
    );
  }

  if (
    props.column.name === 'PeriodEnd' ||
    props.column.name === 'PeriodStart' ||
    props.column.name === 'StatementId'
  ) {
    return <StyledEditCell {...props} editingEnabled={!isEditable} tabIndex={0} />;
  }

  // Add StaticEditCell for LTD Claimed Employees
  if (props.column.name === 'workstatus') {
    return isClaimedEmployee ? (
      <StaticEditCell />
    ) : (
      <LookupEditCell
        accountid={accountid}
        authToken={authToken}
        ltdCompany={ltdCompany}
        readOnlyMode={!isEditable}
        {...props}
      />
    );
  }
  // If an employee is receiving LTD claim, the work status start date should be not required.
  if (props.column.name === "workstatusstartdate") {
    return isClaimedEmployee ? (
      <StyledEditCell
        {...props}
        tabIndex={0}
        editingEnabled={false}
        value={"Not Required"}
      />
    ) : (
      <StyledEditCell {...props}>
        <NativeDateEditCell {...props} />
      </StyledEditCell>
    );
  }

  if (props.column.name === 'employmenttype') {
    return (
      <LookupEditCell
        accountid={accountid}
        authToken={authToken}
        ltdCompany={'N'}
        readOnlyMode={!isEditable}
        {...props}
      />
    );
  }

  // If an employee is receiving LTD claim, the work status end date should be not required.
  if (props.column.name === "workstatusenddate") {
    const { workstatus, enddate } = props.row;
  
    // Check if workstatus is valid and matches the condition
    if (isValid(workstatus)) {
      const trimmedWorkStatus = workstatus.trim();
  
      // New wrapper condition
      if ((workstatus === trimmedWorkStatus && enddate === "N") || isClaimedEmployee) {
        return (
          <StyledEditCell
            {...props}
            tabIndex={0}
            editingEnabled={false}
            value={"Not Required"}
          />
        );
      } else {
        return (
          <StyledEditCell {...props}>
            <NativeDateEditCell {...props} />
          </StyledEditCell>
        );
      }
    } else {
      return (
        <StyledEditCell {...props}>
          <NativeDateEditCell {...props} />
        </StyledEditCell>
      );
    }
  }
  
  if (props.column.name === 'Surname' || props.column.name === 'Givennames') {
    return <StyledEditCell {...props} editingEnabled={true} tabIndex={0} />;
  }

  if (props.column.name === 'penhrs' && props.tableRow.row.haspenhrs !== '1') {
    let editmsg = '';
    switch (props.row.haspenhrs) {
      case '2':
        editmsg = 'Employee is older than 71; pension hours worked are not contributable.';
        break;
      default:
        editmsg = 'Employee does not contribute to Pension Plan.';
        break;
    }
    return (
      <StyledEditCell
        {...props}
        onFocus={(e) => {
          enqueueSnackbar(editmsg, { variant: 'warning' });
        }}
        editingEnabled={true}
        tabIndex={0}
      />
    );
  }

  if (
    props.column.name === 'ltdhrs' &&
    (props.tableRow.row.hasltdhrs !== '1' ||
      (props.tableRow.row.employmenttype.length !== 0 &&
        props.tableRow.row.employmenttype !== 'REGULAR'))
  ) {
    let editmsg = '';
    switch (props.row.hasltdhrs) {
      case '0':
        editmsg = 'Employee does not contribute to LTD Plan.';
        break;
      case '2':
        editmsg = 'Employee is older than 59.5; LTD hours worked are not contributable.';
        break;
      default:
        editmsg = 'Casual employees do not qualify for LTD coverage/benefits.';
        break;
    }

    return (
      <StyledEditCell
        {...props}
        onFocus={(e) => {
          enqueueSnackbar(editmsg, { variant: 'warning' });
        }}
        editingEnabled={true}
        tabIndex={0}
      />
    );
  }

  return <StyledEditCell {...props} editingEnabled={isEditable} tabIndex={0} />;
};

// Date Formatter
const DateFormatter = ({ value }) =>
  value ? (
    <TextField
      type="date"
      value={value}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  ) : (
    ''
  );

const TableValidation = ({
  rows,
  columns,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementrow,
  onRowsChange,
  selection,
  switchChanges,
  handleSelectionChange,
  onEmployeeValidationOpen,
  components,
}) => {
  // authToken Session
  const { authToken } = useSession();

  // Notistack
  const { enqueueSnackbar } = useSnackbar();

  // On Claim Employee - Image Icon
  const OnClaimLTD = '../assets/images/on_claim.png';

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 100, 1000]);

  // Focused Cell
  const [focusedCell, setFocusedCell] = useState(undefined);

  // Pagination Functions
  const getCurrentPageRows = () => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return rows.slice(start, end);
  };

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const displayRows = getCurrentPageRows();

  // Get the row id
  const getRowId = useCallback((row) => row.__index, []);

  // Validate - Edit Remove
  const editsRemove = (edits, value) => {
    return (edits || []).filter((e) => e !== value);
  };

  // Validate - Edit Add
  const editsAdd = (edits, value) => {
    edits = edits || [];
    if (!edits.includes(value)) {
      edits.push(value);
    }
    return edits;
  };

  // Commit changes
  const commitChanges = ({ changed }) => {
    let changedRows = [];
    let modifiedRow = {};
    let original = {};

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (changed) {
      let key = Object.keys(changed)[0];
      original = rows.filter((row) => row.__index === key)[0];

      // Check if the row has errors
      if (typeof changed[key] !== 'undefined') {
        switch (Object.keys(changed[key]).toString()) {
          case 'penhrs':
            if (!isNaN(changed[key].penhrs)) {
              changed[key].penhrs *= 1;
            } else {
              changed[key].penhrs = 0;
              enqueueSnackbar('Invalid number keyed.', { variant: 'error' });
            }

            if (changed[key].penhrs < 0) {
              enqueueSnackbar('Pension hours must be greater than or equal to zero.', {
                variant: 'error',
              });
              original.edits = editsAdd(original.edits, 'Pension hours must be greater than or equal to zero.');
            } else {
              original.edits = editsRemove(original.edits, 'Pension hours must be greater than or equal to zero.');
            }

            if (
              changed[key].penhrs >= totalMaxHoursPerPayPeriod &&
              original.haspenhrs === '1' &&
              original.employmenttype === 'REGULAR'
            ) {
              enqueueSnackbar(
                'Pension hours must be less than ' +
                  totalMaxHoursPerPayPeriod +
                  ' hours per billing statement.',
                {
                  variant: 'error',
                }
              );
              changed[key].penhrs = original.penhrs;
            }
            if (
              changed[key].penhrs < original.ltdhrs &&
              original.hasltdhrs === '1' &&
              original.haspenhrs === '1' &&
              original.employmenttype === 'REGULAR'
            ) {
              enqueueSnackbar('LTD Hours must be less than or equal to Pension Hours.', {
                variant: 'error',
              });
              changed[key].ltdhrs = changed[key].penhrs;
            }
            break;
          case 'ltdhrs':
            if (!isNaN(changed[key].ltdhrs)) {
              changed[key].ltdhrs *= 1;
            } else {
              changed[key].ltdhrs = 0;
              enqueueSnackbar('Invalid number keyed.', { variant: 'error' });
            }

            if (changed[key].ltdhrs < 0) {
              enqueueSnackbar('LTD hours must be >= zero.', {
                variant: 'error',
              });
              original.edits = editsAdd(original.edits, 'LTD hours must be >= zero.');
            } else {
              original.edits = editsRemove(original.edits, 'LTD hours must be >= zero.');
            }

            if (
              changed[key].penhrs >= totalMaxHoursPerPayPeriod &&
              original.hasltdhrs === '1' &&
              original.haspenhrs === '1' &&
              original.employmenttype === 'REGULAR'
            ) {
              enqueueSnackbar(
                'LTD hours must be less than ' +
                  totalMaxHoursPerPayPeriod +
                  ' hours per billing statement.',
                {
                  variant: 'error',
                }
              );
              changed[key].ltdhrs = original.ltdhrs;
            }

            if (
              changed[key].ltdhrs > original.penhrs &&
              original.hasltdhrs === '1' &&
              original.haspenhrs === '1' &&
              original.employmenttype === 'REGULAR'
            ) {
              enqueueSnackbar('LTD Hours must be less than or equal to Pension Hours.', {
                variant: 'error',
              });
              changed[key].ltdhrs = original.penhrs;
            }

            if (
              original.hasltdhrs !== '1' ||
              (original.employmenttype.length !== 0 && original.employmenttype !== 'REGULAR')
            ) {
              let editmsg = '';
              switch (original.hasltdhrs) {
                case '0':
                  editmsg =
                    'The employee has not opted for the LTD Plan. Hence, LTD hours are set to 0.';
                  break;
                case '2':
                  editmsg =
                    'The employee is not a part of the LTD Plan. Therefore, LTD hours are set to 0.';
                  break;
                default:
                  editmsg =
                    'Casual employees are not eligible for LTD coverage or benefits. As a result, LTD hours are set to 0.';
                  break;
              }
              enqueueSnackbar(editmsg, { variant: 'info' });
              changed[key].ltdhrs = 0;
            }

            break;
          case 'workstatus':
            if (onClaimEmployeeIds.includes(original.__index)) {
            } else {
              let lov = JSON.parse(sessionStorage.getItem('workstatus'));
              let status = lov
                .filter((i) => i.value === changed[key].workstatus)
                .map((i) => i.label);
              if (original.enddate !== 'N') {
                changed[key].workstatusstartdate = greaterDate(
                  statementrow.periodstart,
                  original.curr_workstatusstartdate
                );
              }
              if (original.enddate === 'N') {
                enqueueSnackbar(
                  'Status changed to ' + status + '; please update the status start date.',
                  { variant: 'warning' }
                );
                changed[key].workstatusenddate = '';
                changed[key].workstatusstartdate = greaterDate(
                  statementrow.periodstart,
                  original.curr_workstatusstartdate
                );
              } else {
                enqueueSnackbar(
                  'Status changed to ' + status + '; please update the status start and end dates.',
                  { variant: 'warning' }
                );
              }
            }
            break;
          case 'workstatusstartdate':
            if (original.workstatusstartdate !== changed[key].workstatusstartdate) {
              changed[key].workstatus = original.workstatus;
            }

            if (!onClaimEmployeeIds.includes(original.__index)) {
              if (
                parse(original.curr_workstatusstartdate, 'yyyy-MM-dd', new Date()) >
                parse(changed[key].workstatusstartdate, 'yyyy-MM-dd', new Date())
              ) {
                enqueueSnackbar(
                  'Work Status Start must be > prior work start date that we have on file.',
                  {
                    variant: 'error',
                  }
                );
                original.edits = editsAdd(
                  original.edits,
                  'Work Status Start must be > prior work start date that we have on file.'
                );
              } else {
                original.edits = editsRemove(
                  original.edits,
                  'Work Status Start must be > prior work start date that we have on file.'
                );
              }
            }
            if (isAfter(parse(changed[key].workstatusstartdate, 'yyyy-MM-dd', new Date()), today)) {
              enqueueSnackbar('Workstatus Start Date is in the future.', {
                variant: 'error',
              });
              original.edits = editsAdd(original.edits, 'Workstatus Start Date is in the future.');
            } else {
              original.edits = editsRemove(
                original.edits,
                'Workstatus Start Date is in the future.'
              );
            }

            if (
              isBefore(
                parse(changed[key].workstatusstartdate, 'yyyy-MM-dd', new Date()),
                addMonths(today, -12)
              )
            ) {
              enqueueSnackbar('Workstatus Start Date is more than a year in the past.', {
                variant: 'warning',
              });
            }

            if (original.enddate === 'N') {
              if (original.edits.length === 0) {
                enqueueSnackbar('Status has been updated.', {
                  variant: 'success',
                });
              }
            } else {
              enqueueSnackbar('Please update the status end date.', {
                variant: 'warning',
              });
            }
            break;
          case 'workstatusenddate':
            if (original.workstatusenddate !== changed[key].workstatusenddate) {
              changed[key].workstatus = original.workstatus;
              changed[key].workstatusstartdate = original.workstatusstartdate;
            }
            if (
              parse(changed[key].workstatusenddate, 'yyyy-MM-dd', new Date()) <
              parse(original.workstatusstartdate, 'yyyy-MM-dd', new Date())
            ) {
              enqueueSnackbar('Workstatus End Date must be >= Start Date.', {
                variant: 'error',
              });
              original.edits = editsAdd(
                original.edits,
                'Workstatus End Date must be >= Start Date.'
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                'Workstatus End Date must be >= Start Date.'
              );
            }

            if (isAfter(parse(changed[key].workstatusenddate, 'yyyy-MM-dd', new Date()), today)) {
              enqueueSnackbar('Workstatus End Date is in the future.', {
                variant: 'error',
              });
              original.edits = editsAdd(original.edits, 'Workstatus End Date is in the future.');
            } else {
              original.edits = editsRemove(original.edits, 'Workstatus End Date is in the future.');
            }

            if (
              isBefore(
                parse(changed[key].workstatusenddate, 'yyyy-MM-dd', new Date()),
                addMonths(today, -12)
              )
            ) {
              enqueueSnackbar('Workstatus End Date is more than a year in the past.', {
                variant: 'warning',
              });
            }

            if (original.edits.length === 0) {
              enqueueSnackbar('Status has been updated.', {
                variant: 'success',
              });
            }

            break;
          default:
            break;
        }
        modifiedRow = { ...original, ...changed[key] };
      } else {
        modifiedRow = { ...original };
      }
      changedRows = rows.map((row) => (changed[row.__index] ? modifiedRow : row));
      const modifiedRowIndex = changedRows.findIndex((row) => row.__index === key);
      onRowsChange(changedRows, { indexes: [modifiedRowIndex], columns: {} });
    }
  };

  // Pre Edit Cell Component
  const PreEditCell = useCallback(
    (props) => {
      return (
        <EditCell
          accountid={accountid}
          authToken={authToken}
          ltdCompany={'Y'}
          isEditable={true}
          onClaimEmployeeIds={onClaimEmployeeIds}
          {...props}
        />
      );
    },
    [accountid, authToken, onClaimEmployeeIds]
  );

  // Pre Filter Cell Component
  const PreFilterCell = useCallback(
    (props) => <FilterCell accountid={accountid} authToken={authToken} {...props} />,
    [accountid, authToken]
  );

  // Cell component
  const Cell = (props) => {
    // Define an object to map column names to their corresponding error fields
    const errorFields = {
      CompanyNumber: 'CompanyNumber',
      StatementId: 'StatementId',
      PeriodStart: 'PeriodStart',
      PeriodEnd: 'PeriodEnd',
      SIN: 'SIN',
      Surname: 'Surname',
      Givennames: 'Givennames',
      employmenttype: 'employmenttype',
      workstatusstartdate: 'workstatusstartdate',
      workstatusenddate: 'workstatusenddate',
      workstatus: 'workstatus',
      ltdhrs: 'ltdhrs',
      penhrs: 'penhrs',
    };

    // Determine the background color based on the presence of errors
    let backgroundColor = 'transparent';
    const columnHasError = errorFields[props.column.name];

    if (props.row.__errors && props.row.__errors[columnHasError]) {
      backgroundColor = 'lightcoral';
    }

    if (props.row.__warnings) {
      backgroundColor = '#FFDC4C';
    }

    const cellError = props.row.__errors ? props.row.__errors[columnHasError] : null;

    // Generate error messages
    let errorMessages = '';
    if (props.row.__errors) {
      errorMessages = Object.entries(props.row.__errors)
        .filter(([fieldName, error]) => fieldName === props.column.name)
        .map(([fieldName, error]) => `${error.message}`)
        .join('\n');
    }

    // Check if the Employee id is part of the list of employees on LTD Claim.
    const isClaimedEmployee = onClaimEmployeeIds.includes(props.row.employeelistid);

    // Handle the employee validation Dialog
    const handleCellClick = () => {
      onEmployeeValidationOpen(
        props.row.employeelistid,
        props.row.__index,
        props.row.Givennames,
        props.row.Surname,
        props.row.givennames,
        props.row.surname
      );
    };

    if (props.column.name === 'Givennames' && props.row.warnings.length !== 0) {
      const title = props.row.warnings.join('\n');

      return (
        <FocusableCell {...props} title={title} style={{ backgroundColor }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!props.row.warnings.includes('On LTD Claim.') &&
              !props.row.warnings.includes('Pension in Payment.') && (
                <WarningIcon
                  sx={{ color: orange[500], height: '20px', width: '20px' }}
                  fontSize="small"
                />
              )}
            {props.row.warnings.includes('On LTD Claim.') && (
              <>
                <IconButton size="small">
                  <img
                    src={OnClaimLTD}
                    alt="On Claim"
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </IconButton>
              </>
            )}
            {props.value}
          </div>
        </FocusableCell>
      );
    }

    if (props.column.name === 'workstatus') {
      if (isClaimedEmployee) {
        return <StaticEditCell />;
      }
    }

    if (props.column.name === 'workstatusstartdate') {
      if (isClaimedEmployee) {
        return <VirtualTable.Cell value={'Not Required'} />;
      }
    }

    if (props.column.name === 'workstatusenddate') {
      if (isClaimedEmployee) {
        return <VirtualTable.Cell value={'Not Required'} />;
      }
    }

    if (props.column.name === 'penhrs' && props.row.haspenhrs !== '1') {
      return (
        <FocusableCell
          {...props}
          title={'Employee does not contribute to Pension Plan.'}
          tabIndex={0}
          style={{ backgroundColor }}
        >
          {props.value}
          {cellError && errorMessages && (
            <BootstrapTooltip
              arrow
              title={<Typography variant="body2">{errorMessages}</Typography>}
              placement="top"
            >
              <ErrorIcon style={{ color: '#d14124' }} fontSize="small" />
            </BootstrapTooltip>
          )}
        </FocusableCell>
      );
    }

    if (
      props.column.name === 'Surname' &&
      cellError &&
      errorMessages === 'Employee name does not match'
    ) {
      return (
        <ThemeProvider theme={cellTheme}>
          <FocusableCell
            {...props}
            tabIndex={0}
            onClick={handleCellClick}
            style={{ backgroundColor }}
          >
            {props.value}
            {cellError && errorMessages && (
              <BootstrapTooltip
                arrow
                title={<Typography variant="body2">{errorMessages}</Typography>}
                placement="top"
              >
                <DomainVerificationIcon
                  style={{ color: '#d14124', paddingTop: '5px' }}
                  fontSize="medium"
                  onClick={handleCellClick}
                />
              </BootstrapTooltip>
            )}
          </FocusableCell>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={cellTheme}>
        <FocusableCell {...props} tabIndex={0} style={{ backgroundColor }}>
          {props.value}
          {cellError && errorMessages && (
            <BootstrapTooltip
              arrow
              title={<Typography variant="body2">{errorMessages}</Typography>}
              placement="top"
            >
              <ErrorIcon style={{ color: '#d14124' }} fontSize="small" />
            </BootstrapTooltip>
          )}
          {props.column.name === 'workstatusenddate' &&
          props.row.__warnings &&
          props.row.__warnings.workstatusenddate ? (
            <BootstrapTooltip
              arrow
              title={
                <Typography variant="body2">
                  {'Work Status End Date is before Pay Period Start Date'}
                </Typography>
              }
              placement="top"
            >
              <WarningIcon sx={{ color: orange[500] }} fontSize="small" />
            </BootstrapTooltip>
          ) : null}
        </FocusableCell>
      </ThemeProvider>
    );
  };

  return (
    <Paper>
      <Grid
        rows={switchChanges ? rows : displayRows}
        columns={columns}
        getRowId={getRowId}
        components={components}
      >
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={changePageSize}
        />
        <CustomPaging totalCount={Math.ceil(rows.length)} />
        <PagingPanel pageSizes={pageSizes} />
        <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
        <IntegratedSelection />
        <DataMaskingProvider for={['SIN']} />
        <DataTypeProvider
          for={['workstatusstartdate', 'workstatusenddate']}
          formatterComponent={DateFormatter}
        />
        <EditingState onCommitChanges={commitChanges} />
        <SortingState />
        <IntegratedSorting />
        <FilteringState />
        <IntegratedFiltering />
        <VirtualTable height="auto" cellComponent={Cell} />
        <TableHeaderRow contentComponent={TableHeaderContent} showSortingControls={true} />
        <TableFilterRow cellComponent={PreFilterCell} />
        <TableSelection
          cellComponent={CellComponent}
          headerCellComponent={HeaderCellComponent}
          showSelectAll
        />
        <Toolbar />
        <TableInlineCellEditing
          startEditAction={'click'}
          selectTextOnEditStart={true}
          cellComponent={PreEditCell}
        />
        <TableKeyboardNavigation
          focusedCell={focusedCell}
          onFocusedCellChange={setFocusedCell}
        />
      </Grid>
    </Paper>
  );
};

export default TableValidation;
