import React from 'react';

// Material-UI
import { Dialog, DialogContent } from '@mui/material';

// Project Imports
import MakePAD from './MakePAD';

function MakePADDialog(props) {
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Dialog open={props.open} closeAfterTransition={false}>
        <DialogContent>
          <MakePAD {...props} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MakePADDialog;
