import React, { useState, useEffect, useCallback, Fragment, lazy, Suspense } from "react";

// AWS Amplify - API - Request Handler
import { handleGet, handlePut } from "../../utils/amplifyInstance";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Material ui
import {
  Box,
  Paper,
  Tooltip,
  IconButton,
  TextField,
  TableCell,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

// Devexpress - React Grid
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  RowDetailState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

// Devexpress - React Grid Material UI
import {
  Grid,
  VirtualTable,
  TableKeyboardNavigation,
  TableHeaderRow,
  TableRowDetail,
  TableInlineCellEditing,
  TableColumnVisibility,
  TableFilterRow,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

// Helper functions
import {
  FocusableCell,
  LookupEditCell,
  LookupFilterCell,
  NativeDateEditCell,
  NativeDateFilterCell,
  SINMaskingFormatter,
  StyledEditCell,
} from "../../common/HelperFunctions";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

// Third party
import { useSnackbar } from "notistack";
import { parse, isAfter, isBefore, addMonths } from "date-fns";

// Material ui - Style
import { orange } from "@mui/material/colors";

// Icons
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Plugins
import ELToolbarActions from "../../plugins/ELToolbarActions";

// Lazy loading
const EmployeeDialog = lazy(() => import("./EmployeeDialog"));

const getRowId = (row) => row.employeelistid;

const comparePriority = (a, b) => {
  const x = a === "N/A" ? 9999999 : a;
  const y = b === "N/A" ? 9999999 : b;

  if (x === y) {
    return 0;
  }
  return x < y ? -1 : 1;
};

const DataMaskingProvider = (props) => (
  <DataTypeProvider formatterComponent={SINMaskingFormatter} {...props} />
);

const StyledTable = styled(VirtualTable.Table)(({ theme }) => ({
  "& tbody tr:nth-of-type(odd)": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    marginRight: "28px",
  },
}));

const TableComponent = ({ ...restProps }) => <StyledTable {...restProps} />;

const TableHeaderContent = ({
  column,
  children,
  showTAOnly,
  setShowTAOnly,
  classes,
  ...restProps
}) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold",
    }}
  >
  {children}
  </TableHeaderRow.Content>
);

const FilterCell = (props) => {
  const { column } = props;

  if (["edits"].includes(column.name)) {
    return <TableCell sx={{ width: "100%", px: 1, py: 0 }} />;
  }

  if (["employmenttype"].includes(column.name)) {
    return (
      <TableCell sx={{ width: "100%", px: 1, py: 0 }}>
        <LookupFilterCell
          accountid={props.accountid}
          name={column.name}
          filter={props.filter}
          readOnlyMode={false}
          onFilter={props.onFilter}
          authToken={props.authToken}
          {...props}
        />
      </TableCell>
    );
  }

  if (
    [
      "hiredate",
      "senioritydate",
      "workstatusstartdate",
      "workstatusenddate",
    ].includes(column.name)
  ) {
    return (
      <TableCell sx={{ width: "100%", px: 1, py: 0 }}>
        <NativeDateFilterCell
          filter={props.filter}
          onFilter={props.onFilter}
          {...props}
        />
      </TableCell>
    );
  }

  return <TableFilterRow.Cell {...props} />;
};

const Cell = (props) => {
  // On Claim LTD Icon
  const OnClaimLTD = "/assets/images/on_claim.png"

  if (props.column.name === "edits" && props.row.edits.length !== 0) {
    const title = props.row.edits.join("\n");

    return (
      <FocusableCell {...props} title={title}>
        <ErrorIcon color={"secondary"} fontSize={"small"} />
      </FocusableCell>
    );
  }

  if (props.column.name === "name" && props.row.warnings.length !== 0) {
    const title = props.row.warnings.join("\n");

    return (
      <FocusableCell {...props} title={title}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!props.row.warnings.includes("On LTD Claim.") &&
            !props.row.warnings.includes("Pension in Payment.") && (
              <WarningIcon
                sx={{ color: orange[500], height: "17.5px", width: "17.5px" }}
                fontSize={"small"}
              />
            )}
          {props.row.warnings.includes("On LTD Claim.") && (
            <IconButton size="small">
              <img
                src={OnClaimLTD}
                alt={"On Claim"}
                style={{
                  height: "17.5px",
                  width: "17.5px",
                }}
              />
            </IconButton>
          )}
          {props.value}
        </div>
      </FocusableCell>
    );
  }

  if (props.column.name === "fundedhours") {
    return <FocusableCell {...props} title={"Total funded hours reported"} />;
  }

  if (props.column.name === "unfundedhours") {
    return <FocusableCell {...props} title={"Total unfunded hours reported"} />;
  }

  return <FocusableCell {...props} />;
};

const EditCell = ({ accountid, authToken, ...props }) => {
  if (props.column.name === "edits" && props.row.edits.length !== 0) {
    const title = props.row.edits.join("\n");

    return (
      <StyledEditCell {...props} tabIndex={-1} title={title}>
        <ErrorIcon color={"secondary"} fontSize={"small"} />
      </StyledEditCell>
    );
  }

  if (
    props.column.name === "workstatus" ||
    props.column.name === "employmenttype"
  ) {
    return (
      <LookupEditCell accountid={accountid} authToken={authToken} readOnlyMode={false} {...props} />
    );
  }

  if (
    props.column.name === "hiredate" ||
    props.column.name === "senioritydate" ||
    props.column.name === "workstatusstartdate"
  ) {
    return (
      <StyledEditCell {...props}>
        <NativeDateEditCell {...props} />
      </StyledEditCell>
    );
  }

  if (props.column.name === "workstatusenddate") {
    if (props.tableRow.row.enddate === "N") {
      return (
        <StyledEditCell
          {...props}
          editingEnabled={false}
          value={"Not Required"}
        />
      );
    } else {
      return (
        <StyledEditCell {...props}>
          <NativeDateEditCell {...props} />
        </StyledEditCell>
      );
    }
  }

  return <StyledEditCell tabIndex={-1} {...props} />;
};

const StyledToggleCell = styled(VirtualTable.Cell)(({ theme }) => ({
  "&:first-of-type": {
    paddingLeft: "8px !important",
  },
}));

const ToggleCell = ({ handleExpandedRowIdChange, ...props }) => {
  return (
    <StyledToggleCell>
      {props.expanded ? (
        <Tooltip title="Close employee information form">
          <IconButton
            size="small"
            onClick={() => {
              handleExpandedRowIdChange([]);
            }}
          >
            <ExpandLessIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Open employee information form">
          <IconButton
            size="small"
            onClick={() => {
              handleExpandedRowIdChange([props.tableRow.rowId]);
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      )}
    </StyledToggleCell>
  );
};

const DateFormatter = ({ value }) =>
  value ? (
    <TextField
      type="date"
      value={value}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  ) : (
    ""
  );

// ===========================|| EMPLOYEE LIST ||=========================== //

const EmployeeList = () => {
  const { authToken } = useSession();
  const { account, companyid, onlyLTD } = useMainContext();
  
  const accountid = account.map((i) => i.accountid)[0];
  const roleid = account.map((i) => i.roleid)[0];
  const showHours = onlyLTD === "N" ? true : false;

  const { enqueueSnackbar } = useSnackbar();

  const [columns] = useState([
    { name: "edits", title: " " },
    { title: "SIN", name: "sin" },
    { name: "id", title: "ID" },
    {
      title: "Name",
      name: "name",
      getCellValue: (row) => row.surname + ", " + row.givennames,
    },
    { name: "employmenttype", title: "Work Type" },
    { name: "hiredate", title: "Hire Date" },
    { name: "senioritydate", title: "Seniority Date" },
    { name: "fundedhours", title: "Prior Year's Funded Hours" },
    { name: "unfundedhours", title: "Prior Year's Unfunded Hours" },
    {
      name: "totalhours",
      title: "Prior Year's Total Hours",
      getCellValue: (row) => row.fundedhours * 1 + row.unfundedhours * 1,
    },
  ]);

  const [columnExtensions] = useState([
    { columnName: "edits", align: "left", width: "30px" },
    { columnName: "sin", width: "90px" },
    { columnName: "id", align: "left", width: "70px" },
    { columnName: "name", width: "275px" },
    { columnName: "employmenttype", width: "120px" },
    { columnName: "hiredate", width: "110px" },
    { columnName: "senioritydate", width: "120px" },
    {
      columnName: "fundedhours",
      wordWrapEnabled: true,
      align: "right",
      width: "110px",
    },
    {
      columnName: "unfundedhours",
      wordWrapEnabled: true,
      align: "right",
      width: "110px",
    },
    {
      columnName: "totalhours",
      wordWrapEnabled: true,
      align: "right",
      width: "110px",
    },
  ]);
  const [editingStateColumnExtensions] = useState([
    { columnName: "edits", editingEnabled: false },
    { columnName: "sin", editingEnabled: false },
    { columnName: "id", editingEnabled: false },
    { columnName: "name", editingEnabled: false },
    { columnName: "fundedhours", editingEnabled: false },
    { columnName: "unfundedhours", editingEnabled: false },
    { columnName: "totalhours", editingEnabled: false },
  ]);

  const [integratedSortingColumnExtensions] = useState([
    { columnName: "fundedhours", compare: comparePriority },
    { columnName: "unfundedhours", compare: comparePriority },
    { columnName: "totalhours", compare: comparePriority },
  ]);

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showTAOnly, setShowTAOnly] = useState("Y");
  const [focusedCell, setFocusedCell] = useState(null);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !companyid || !authToken) {
        return;
      }

      try {
          // let response = await get(
          //   "EmployerApiGw",
          //   "/employer/" +
          //     accountid +
          //     "/" +
          //     companyid +
          //     "/employeelist?ta=" +
          //     showTAOnly
          // );

        let response = await handleGet({
          apiName: "EmployerApiGw",
          path: "/employer/" + accountid + "/" + companyid + "/employeelist?ta=" + showTAOnly,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(json, (k, v) => {
            return k === "edits" || k === "warnings"
              ? v == null
                ? []
                : v.split(",")
              : v == null
              ? ""
              : v;
          })
        );

        setData(response.items);
      } catch (e) {
        console.log(e);
        setData([]);
      }

      setRefresh(false);
      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [refresh, showTAOnly, accountid, companyid, authToken]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setRowCount(data.length);
  }, [data]);

  useEffect(() => {
    if (!openDialog) {
      setExpandedRowId([]);
    }
  }, [openDialog]);

  const updateData = async (rowData) => {
    let esid = 0;

    try {
      // const response = await put(
      //   "EmployerApiGw",
      //   "/employer/" + accountid + "/" + companyid + "/employeelist",
      //   {
      //     body: [rowData],
      //   }
      // );
      const response = await handlePut({
        apiName: "EmployerApiGw",
        path: "/employer/" + accountid + "/" + companyid + "/employeelist",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [rowData],
      });

      const { body } = await response;
      const json = await body.json();


      esid =
        "employeestatusid" in json
          ? json.employeestatusid
          : rowData.employeestatusid;

      if (json.result === -1) {
        enqueueSnackbar(json.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }

    return esid;
  };

  const editsRemove = (edits, value) => {
    return (edits || []).filter((e) => e !== value);
  };

  const editsAdd = (edits, value) => {
    edits = edits || [];
    if (!edits.includes(value)) {
      edits.push(value);
    }

    return edits;
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows = [];
    let modifiedRow = {};
    let original = {};

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (changed) {
      let elid = Object.keys(changed)[0] * 1;
      original = data.filter((row) => row.employeelistid === elid)[0];

      if (typeof changed[elid] !== "undefined") {
        switch (Object.keys(changed[elid]).toString()) {
          case "hiredate":
            if (
              isBefore(
                parse(changed[elid].hiredate, "yyyy-MM-dd", new Date()),
                addMonths(today, -12)
              )
            ) {
              enqueueSnackbar("Hire Date is more than a year in the past.", {
                variant: "warning",
              });
            }

            break;
          case "senioritydate":
            if (
              isBefore(
                parse(changed[elid].senioritydate, "yyyy-MM-dd", new Date()),
                addMonths(today, -12)
              )
            ) {
              enqueueSnackbar(
                "Seniority Date is more than a year in the past.",
                { variant: "error" }
              );
              original.edits = editsAdd(
                original.edits,
                "Seniority Date is more than a year in the past."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Seniority Date is more than a year in the past."
              );
            }

            break;
          case "workstatus":
            let lov = JSON.parse(sessionStorage.getItem("workstatus"));
            let status = lov
              .filter((i) => i.value === changed[elid].workstatus)
              .map((i) => i.label);

            if (original.enddate === "N") {
              enqueueSnackbar(
                "Status changed to " +
                  status +
                  "; please update the status start date.",
                { variant: "warning" }
              );
              changed[elid].workstatusenddate = "";
            } else {
              enqueueSnackbar(
                "Status changed to " +
                  status +
                  "; please update the status start and end dates.",
                { variant: "warning" }
              );
            }

            break;
          case "workstatusstartdate":
            if (changed[elid].workstatusstartdate.length === 0) {
              enqueueSnackbar("Workstatus Start Date is required.", {
                variant: "error",
              });
              original.edits = editsAdd(
                original.edits,
                "Workstatus Start Date is required."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Workstatus Start Date is required."
              );
            }

            if (
              parse(original.workstatusenddate, "yyyy-MM-dd", new Date()) <
              parse(changed[elid].workstatusstartdate, "yyyy-MM-dd", new Date())
            ) {
              enqueueSnackbar("Workstatus End Date must be >= Start Date.", {
                variant: "error",
              });
              original.edits = editsAdd(
                original.edits,
                "Workstatus End Date must be >= Start Date."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Workstatus End Date must be >= Start Date."
              );
            }

            if (
              isAfter(
                parse(
                  changed[elid].workstatusstartdate,
                  "yyyy-MM-dd",
                  new Date()
                ),
                today
              )
            ) {
              enqueueSnackbar("Workstatus Start Date is in the future.", {
                variant: "error",
              });
              original.edits = editsAdd(
                original.edits,
                "Workstatus Start Date is in the future."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Workstatus Start Date is in the future."
              );
            }

            if (
              isBefore(
                parse(
                  changed[elid].workstatusstartdate,
                  "yyyy-MM-dd",
                  new Date()
                ),
                addMonths(today, -12)
              )
            ) {
              enqueueSnackbar(
                "Workstatus Start Date is more than a year in the past.",
                { variant: "warning" }
              );
            }

            if (original.enddate === "N") {
              if (original.edits.length === 0) {
                enqueueSnackbar("Status has been updated.", {
                  variant: "success",
                });
              }
            } else {
              enqueueSnackbar("Please update the status end date.", {
                variant: "warning",
              });
            }

            break;
          case "workstatusenddate":
            if (
              parse(changed[elid].workstatusenddate, "yyyy-MM-dd", new Date()) <
              parse(original.workstatusstartdate, "yyyy-MM-dd", new Date())
            ) {
              enqueueSnackbar("Workstatus End Date must be >= Start Date.", {
                variant: "error",
              });
              original.edits = editsAdd(
                original.edits,
                "Workstatus End Date must be >= Start Date."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Workstatus End Date must be >= Start Date."
              );
            }

            if (
              isAfter(
                parse(
                  changed[elid].workstatusenddate,
                  "yyyy-MM-dd",
                  new Date()
                ),
                today
              )
            ) {
              enqueueSnackbar("Workstatus End Date is in the future.", {
                variant: "error",
              });
              original.edits = editsAdd(
                original.edits,
                "Workstatus End Date is in the future."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Workstatus End Date is in the future."
              );
            }

            if (
              isBefore(
                parse(
                  changed[elid].workstatusenddate,
                  "yyyy-MM-dd",
                  new Date()
                ),
                addMonths(today, -12)
              )
            ) {
              enqueueSnackbar(
                "Workstatus End Date is more than a year in the past.",
                { variant: "warning" }
              );
            }

            if (original.edits.length === 0) {
              enqueueSnackbar("Status has been updated.", {
                variant: "success",
              });
            }

            break;
          default:
            break;
        }

        modifiedRow = { ...original, ...changed[elid] };
        if (original.edits.length === 0) {
          (async () => {
            modifiedRow.employeestatusid = await updateData(modifiedRow);
          })();
        }
      }

      changedRows = data.map((row) =>
        changed[row.employeelistid] ? modifiedRow : row
      );
    }

    setData(changedRows);
  };

  const handleExpandedRowIdChange = (expandedRowIds) => {
    if (expandedRowIds.length > 1) {
      expandedRowIds.shift();
    }

    setExpandedRowId(expandedRowIds);
    setOpenDialog(true);
  };

  const ToggleCellBase = (props) => {
    return (
      <ToggleCell
        handleExpandedRowIdChange={handleExpandedRowIdChange}
        {...props}
      />
    );
  };

  const RowDetail = useCallback(
    ({ row }) => {
      return (
        <Suspense fallback={<div>Loading...</div>}>
        <EmployeeDialog
          open={openDialog}
          setOpenDialog={setOpenDialog}
          accountid={accountid}
          companyid={companyid}
          roleid={roleid}
          employeelistid={row.employeelistid}
          setRefresh={setRefresh}
        />
        </Suspense>
      );
    },
    [accountid, companyid, roleid, openDialog]
  );

  const TableHeaderContentBase = (props) => {
    return (
      <TableHeaderContent
        {...props}
        showTAOnly={showTAOnly}
        setShowTAOnly={setShowTAOnly}
      />
    );
  };

  const PreFilterCell = useCallback(
    (props) => <FilterCell accountid={accountid} authToken={authToken} {...props} />,
    [accountid, authToken]
  );

  const PreEditCell = useCallback(
    (props) => <EditCell accountid={accountid} authToken={authToken} {...props} />,
    [accountid, authToken]
  );

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Employee List" />
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#0085224d",
              p: 2,
              borderRadius: 1,
              mb: 1,
              borderTop: "6px solid #008522",
            }}
          >
            <Box display="flex" alignItems="center" m={1}>
              <InfoOutlinedIcon sx={{ color: "#008522" }} />
              <Typography variant="body1" marginLeft={1}>
               You can view and change employee information and work status by clicking down-arrow (icon) on the left of employee row.
              </Typography>
            </Box>
          </Box>
          <Paper>
            <Grid rows={data} columns={columns} getRowId={getRowId}>
              <DataMaskingProvider for={["sin"]} />
              <DataTypeProvider
                for={["fundedhours", "unfundedhours", "totalhours"]}
                availableFilterOperations={["lessThanOrEqual"]}
              />
              <DataTypeProvider
                for={["hiredate", "senioritydate"]}
                formatterComponent={DateFormatter}
              />
              <EditingState
                columnEditingEnabled={roleid === 1 ? true : false}
                onCommitChanges={commitChanges}
                columnExtensions={editingStateColumnExtensions}
              />
              <SortingState />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <FilteringState />
              <IntegratedFiltering />
              <RowDetailState
                expandedRowIds={expandedRowId}
                onExpandedRowIdsChange={handleExpandedRowIdChange}
              />
              <VirtualTable
                height={745}
                tableComponent={TableComponent}
                columnExtensions={columnExtensions}
                cellComponent={Cell}
              />
              <TableHeaderRow
                contentComponent={TableHeaderContentBase}
                showSortingControls
              />
              <TableColumnVisibility
                hiddenColumnNames={
                  showHours
                    ? []
                    : ["fundedhours", "unfundedhours", "totalhours"]
                }
              />
              <TableFilterRow cellComponent={PreFilterCell} />
              {roleid === 1 ? (
                <TableInlineCellEditing
                  startEditAction="click"
                  selectTextOnEditStart
                  cellComponent={PreEditCell}
                />
              ) : null}
              <TableRowDetail
                toggleCellComponent={ToggleCellBase}
                contentComponent={RowDetail}
              />
              <Toolbar />
              <ELToolbarActions
                rowcount={rowCount}
                accountid={accountid}
                companyid={companyid}
                roleid={roleid}
                setRefresh={setRefresh}
                showTAOnly={showTAOnly}
                setShowTAOnly={setShowTAOnly}
              />
              <TableKeyboardNavigation
                focusedCell={focusedCell || { rowIndex: 0, columnIndex: 0 }}
                onFocusedCellChange={setFocusedCell}
              />
            </Grid>
          </Paper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EmployeeList;
