import React, { useEffect, useState, useCallback, Fragment } from 'react';

// Project imports
import UploadFileAttachment from './UploadFileAttachment';

// Third-party
import * as XLSX from 'xlsx';
import { useSnackbar } from 'notistack';
import { parse, format, isValid } from 'date-fns';

// Utils
import datePatterns from '../../utils/datePatterns';

const UploadDropZone = ({ setUploadFileData, setWorkBookData, setLoading }) => {
  const [files, setFiles] = useState([]);

  // Global Var
  const dateFormat = 'yyyy-mm-dd'; // Define your date format
  const parseRaw = false;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (files.length > 0) {
      setUploadFileData(files);
    } else if (files.length === 0) {
      setUploadFileData({});
    }
  }, [files, setUploadFileData]);

  const handleChange = (event) => {
    const newFiles = event.target.files;

    if (newFiles.length > 0) {
      if (files.length >= 1) {
        enqueueSnackbar('Maximum 1 file can be uploaded.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          preventDuplicate: true,
        });
      } else {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
    }
  };

  const handleFilesDrop = useCallback(
    (newFiles) => {
      if (newFiles.length > 0) {
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes Max - Size
        const acceptedFormats = [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ];

        const acceptedExtensions = ['.xls', '.xlsx', '.csv'];

        const invalidFiles = newFiles.filter((file) => {
          const isInvalidType = !acceptedFormats.includes(file.type);
          const isInvalidExtension = !acceptedExtensions.includes(
            file.name.substring(file.name.lastIndexOf('.'))
          );
          return isInvalidType || isInvalidExtension;
        });

        const maxFileSize = newFiles.filter((file) => {
          const isInvalidSize = file.size > maxSize;
          return isInvalidSize;
        });

        if (invalidFiles.length > 0) {
          enqueueSnackbar('Invalid file format. Please upload .xls, .xlsx, or .csv files.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            preventDuplicate: true,
          });
          return;
        }

        if (maxFileSize.length > 0) {
          enqueueSnackbar('Invalid file size. Please upload valid files (max size: 5MB).', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            preventDuplicate: true,
          });
          return;
        }

        if (files.length >= 1) {
          enqueueSnackbar('Maximum 1 file can be uploaded.', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            preventDuplicate: true,
          });
        } else {
          setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
      }
    },
    [files, enqueueSnackbar]
  );

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onDropAccepted = useCallback(
    async ([file]) => {
      setLoading(true);
      const arrayBuffer = await readFileAsync(file);
      const workbook = XLSX.read(arrayBuffer, {
        cellDates: true,
        dateNF: dateFormat,
        raw: parseRaw,
        dense: true,
      });

      const dateRegex = /^(?:(\d{1,2})[-/](\w{3}|\d{1,2})[-/](\d{2,4})|(\w{3}) (\d{1,2}), (\d{4})|(\d{4}) (\w{3}) (\d{1,2}))$/;

      const convertToDate = (dateString) => {
        console.log('dateString', dateString);
        const parts = dateString.split('-');
        const day = parts[0];
        const month = new Date(Date.parse(parts[1] + ' 1, 2021')).getMonth() + 1;
        let year = parts[2];

        // Handle two-digit year
        if (year.length === 2) {
          year = '20' + year;
        }

        return `${year}-${month.toString().padStart(2, '0')}-${day.padStart(2, '0')}`;
      };

      const convertToISODate = (dateString) => {
        for (const pattern of datePatterns) {
          const parsedDate = parse(dateString, pattern, new Date());
          if (isValid(parsedDate)) {
            const formmatedDate = format(parsedDate, 'dd-MMM-yy');
            return convertToDate(formmatedDate);
          }
        }
        return null;
      };

      // Standardize date format in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        // Standardize date format in the sheet
        for (let rowIndex = 0; rowIndex < sheet['!data'].length; rowIndex++) {
          const isValidate = (value) => value !== undefined && value !== null && value !== '';
          const rowValues = sheet['!data'][rowIndex];
          const row = isValidate(sheet['!data'][rowIndex]);

          if (row) {
            for (let colIndex = 0; colIndex < rowValues.length; colIndex++) {
              const cell = rowValues[colIndex];
              if (cell && cell.t === 's') {
                if (dateRegex.test(cell.v)) {
                  const newDateValue = convertToISODate(cell.v);
                  cell.v = newDateValue;
                  cell.w = newDateValue;
                }
              } else if (cell && cell.t === 'd') {
                if (dateRegex.test(cell.w)) {
                  const newDateValue = convertToISODate(cell.w);
                  cell.v = newDateValue;
                  cell.w = newDateValue;
                }
              }
            }
          }
        }
      });

      setLoading(false);
      setWorkBookData(workbook);
    },
    [parseRaw, setWorkBookData, setLoading]
  );

  const handleFileRemove = useCallback((file) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((_file) => _file.path !== file.path);
    });
  }, []);

  const handleFilesRemoveAll = useCallback(() => {
    setFiles([]);
  }, []);

  return (
    <Fragment>
      <UploadFileAttachment
        files={files}
        onChange={handleChange}
        onDrop={handleFilesDrop}
        onDropAccepted={onDropAccepted}
        onRemove={handleFileRemove}
        onRemoveAll={handleFilesRemoveAll}
        maxFileSize={5 * 1024 * 1024}
        maxFiles={1}
      />
    </Fragment>
  );
};

export default UploadDropZone;
