import React, { Fragment } from "react";

// Material UI
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Divider,
  Tooltip,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";

// Project Import
import { ColumnType } from "../MatchColumnsFile";

const EmployerTableColumn = ({
  column,
  handleOnIgnore,
  onRevertIgnore,
  entries,
}) => {
  const isIgnored = column.type === ColumnType.ignored;
  
  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: "rgba(0, 133, 34, 0.3)"}}> 
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "xs",
            lineHeight: 4,
            px: 1,
            fontWeight: "bold",
            letterSpacing: "wider",
            color: isIgnored ? "inactiveColor" : "textColor",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          data-ignored={isIgnored}
        >
          {column.header}
        </Typography>
        {column.type === ColumnType.ignored ? (
          <Tooltip title="Revert Changes">
            <IconButton
              aria-label="Ignore Column"
              sx={{
                size: "xs",
                paddingLeft: "15px",
                colorScheme: "gray",
                color: "textColor",
              }}
              onClick={() => onRevertIgnore(column.index)}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Ignore Column">
            <IconButton
              aria-label="Ignore Column"
              sx={{
                size: "xs",
                colorScheme: "gray",
                color: "textColor",
              }}
              onClick={() => handleOnIgnore(column.index)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider />
      {entries.map((entry, index) => (
        <Grid alignContent="flex-start" key={(entry || "") + index}>
          <Grid item sx={{ height: '60px' }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: "sm",
                lineHeight: 5,
                fontWeight: "medium",
                color: "textColor",
                px: 1,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              data-ignored={isIgnored}
            >
              {entry}
            </Typography>
            <Divider />
          </Grid>
        </Grid>
      ))}
      <Box sx={{ m: entries.length !== 3 ? 17 : 2 }} />
    </Fragment>
  );
};

export default EmployerTableColumn;
